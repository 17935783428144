// Reference colors: Material Design
$materialDesign-color-surface-00: #121212;
$materialDesign-color-surface-01: #1e1e1e;
$materialDesign-color-surface-02: #222222;
$materialDesign-color-surface-03: #242424;
$materialDesign-color-surface-04: #272727;
$materialDesign-color-surface-06: #2c2c2c;
$materialDesign-color-surface-08: #2d2d2d;
$materialDesign-color-surface-12: #323232;
$materialDesign-color-surface-16: #353535;
$materialDesign-color-surface-24: #383838;
$materialDesign-color-error: #cf6679;
$materialDesign-color-text: #ffffff;
$materialDesign-color-text-high: rgba($materialDesign-color-text, 0.87);
$materialDesign-color-text-medium: rgba($materialDesign-color-text, 0.6);
$materialDesign-color-text-low: rgba($materialDesign-color-text, 0.38);

// Reference colors: Google Drive app
$googleDrive-background: #202124; // 32, 33, 36
$googleDrive-footer-background: #36373a; // 54, 55, 58
$googleDrive-fab-background: #353639; // 53, 54, 57
$googleDrive-search-background: #303134; // 48, 49, 52
$googleDrive-account-background: #2d2e30; // 45, 46, 48
$googleDrive-bottomDrawer-background: #353639; // 53, 54, 57

// Reference colors: Gmail app
$gmail-background: #202124; // 32, 33, 36
$gmail-search-background: #303134; // 48, 49, 52

// Reference colors: Google News app
$googleNews-background: #202124; // 32, 33, 36
$googleNews-footer-background: #313235; // 49, 50, 53

// Reference colors: Google Chrome Incognito
// https://www.schemecolor.com/google-chrome-incognito-mode-ui-colors.php
$googleChrome-raisinBlack: #202124; // 32, 33, 36
$googleChrome-charlestonGreen: #282c2f; // 40, 44, 47
$googleChrome-onyx: #323639; // 50, 54, 57
$googleChrome-lightSilver: #d6d6d7; // 214, 214, 215

// Reference colors: Facebook website dark theme
$facebook-dark-background: #18191a; // 24, 25, 26
$facebook-dark-text-high: #e4e6eb; // 228, 230, 235
$facebook-dark-text-medium: #b0b3b8; // 176, 179, 184
$facebook-dark-header: #242526; // 36, 37, 38
$facebook-dark-header-icon: #b0b3b8; // 176, 179, 184
$facebook-dark-input-background-l1: #3a3b3c; // 58, 59, 60
$facebook-dark-input-background-l2: #4e4f50; // 78, 79, 80
$facebook-dark-box-background: #242526; // 36, 37, 38
$facebook-dark-box-icon-mask: #3a3b3c; //58, 59, 60
$facebook-dark-box-icon: #a8abae; // 168, 171, 174
$facebook-dark-black-text-high: #dfe1e4; // 223, 225, 228
$facebook-dark-black-text-medium: #65676b; // 101, 103, 107

// Reference colors: Android Google Chrome PWA header
$googleChrome-pwa-header: #35363b; // 53, 54, 59

// Color scheme
// Red = X
// Green = Red + 1
// Blue = Red + 1
$color-00: rgb(15, 16, 17); // #0f1011
$color-01: rgb(25, 26, 27);
$color-02: rgb(35, 36, 37);
$color-03: rgb(45, 46, 47);
$color-04: rgb(55, 56, 57);
$color-05: rgb(65, 66, 67);
$color-06: rgb(75, 76, 77);
$color-07: rgb(85, 86, 87);
$color-08: rgb(95, 96, 97);
$color-09: rgb(105, 106, 107);

$color-background: $color-00;
$color-background-on: rgb(225, 226, 227);
$color-text: $color-background-on;
$color-text-high: rgba($color-text, 0.9);
$color-text-medium: rgba($color-text, 0.7);
$color-text-low: rgba($color-text, 0.5);
$color-text-dim: rgba($color-text, 0.35);

$color-border: $color-05;
$color-border-dim: $color-01;
$color-border-hover: $color-09;
$color-box: $color-01;
$color-button: $color-05;
$color-button-hover: $color-04;
$color-chip: $color-button;
$color-chip-hover: $color-button-hover;
$color-error: #cf6679; // $materialDesign-color-error
$color-error-on: #000000;
$color-footer: #000000;
$color-hint: $color-02;
$color-input-background: transparent;
$color-modal: $color-02;
$color-negative: #ff5669; // $materialDesign-color-error, but with the red channel increased and green and blue channels decreased
$color-warning: #ff5669; // 255, 86, 105
$color-warning-hover: #eb4255; // 235, 66, 85
$color-warning-on: #000000;

$duration-small: 0.1s;
$duration-medium-collapse: 0.2s;
$duration-medium-expand: 0.25s;
$duration-large-collapse: 0.25s;
$duration-large-expand: 0.3s;
$duration-default: $duration-medium-expand;

$elevation-high: 9;
$elevation-medium: 4;
$elevation-low: 1;
$elevation-hover: $elevation-high;

$radius-box: 0.4rem;
$radius-button: 0.4rem;
$radius-default: 0.4rem;
$radius-circle: 99rem;

$spacing-100: 0.1rem;
$spacing-200: 0.2rem;
$spacing-300: 0.3rem;
$spacing-400: 0.4rem;
$spacing-500: 0.5rem;
$spacing-600: 0.6rem;
$spacing-700: 0.7rem;
$spacing-800: 0.8rem;
$spacing-900: 0.9rem;
$spacing-1000: 1rem;
$spacing-1100: 1.1rem;
$spacing-1300: 1.3rem;
$spacing-1500: 1.5rem;
$spacing-2000: 2rem;
$spacing-3000: 3rem;
$spacing-3500: 3.5rem;
$spacing-4000: 4rem;
$spacing-5000: 5rem;
$spacing-6000: 6rem;
$spacing-default: $spacing-1000;

$spacing-body: $spacing-1500;
$spacing-box: $spacing-1500;
$spacing-box-vertical: $spacing-2000;
$spacing-input-padding-right: ($spacing-default * 3);
$spacing-modal: $spacing-1500;
$spacing-modal-width-max: 60rem;
$spacing-modal-width-min: 30rem;
$spacing-scrollbar: 1.7rem; // Windows 10 Chrome
$spacing-svg-default: 2.4rem;
$spacing-width-max: 90rem;

$spacing-account-top: $spacing-body + ($spacing-default / 2) + 0.4rem; // Offset to match where the clear button is normally in InputText
$spacing-account-right: $spacing-body + $spacing-default; // Offset to match where the clear button is normally in InputText
