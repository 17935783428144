@import "./fonts/roboto/roboto";
@import "./variables";
@import "./fonts";

html {
  height: 100%;
  font-size: 10px; // Scale this number, if necessary
}

body {
  margin: 0;
  height: 100%;
  background: $color-background;
  color: $color-text-medium;
  font-family: $font-family;
  @include font-body1();
}

#root {
  height: 100%;
}

input {
  font-family: $font-family;
  @include font-body1();
}

svg {
  display: block;
}
