@import "./../../styles/variables";
@import "./../../styles/boxes";
@import "./../../styles/shadows";

.containerOuter {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.containerInner {
  position: relative;
  box-sizing: border-box;
  margin: auto;
  max-width: $spacing-modal-width-max;
  padding: $spacing-modal;
}

.modal {
  @include box();
  @include shadow($elevation-high);
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: $spacing-modal-width-min;
  background: $color-modal;
}

.close {
  position: absolute;
  top: $spacing-box-vertical;
  right: $spacing-box;
  fill: $color-text-low;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:active,
  &:hover {
    fill: $color-text-high;
  }
}
