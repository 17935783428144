@import "./../../styles/variables";

.account {
  position: absolute;
  z-index: 999;
  top: $spacing-account-top;
  right: $spacing-account-right;
  fill: $color-text-low;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    fill: $color-text-high;
  }
}
