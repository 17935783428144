@import "./variables";
@import "./shadows";

@mixin box($elevation: $elevation-low) {
  //@include shadow($elevation);
  border-radius: $radius-box;
  padding: $spacing-box;
  padding-top: $spacing-box-vertical;

  // &:hover {
  //   @include shadow-hover();
  // }
}
