@import "./variables";

// https://material.io/design/motion/speed.html#easing

@mixin animation-easing-standard($duration: $duration-default) {
  transition: all $duration cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin animation-easing-decelerate($duration: $duration-default) {
  transition: all $duration cubic-bezier(0, 0, 0.2, 1);
}

@mixin animation-easing-accelerate($duration: $duration-default) {
  transition: all $duration cubic-bezier(0.4, 0, 1, 1);
}
