@import "./../../styles/variables";

$loading-height: $spacing-300;

.loading {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: $loading-height;

  &:before {
    display: block;
    position: absolute;
    left: -20%;
    width: 20%;
    height: $loading-height;
    background-color: $color-text-high;
    animation: loading 2s linear infinite;
    content: "";
  }
}

@keyframes loading {
  from {
    left: -20%;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
