@import "./variables";
@import "./animations";

// https://material.io/design/environment/light-shadows.html

// Alternative: Azure DevOps modal shadow
// box-shadow: 0 25.6px 57.6px rgba(0, 0, 0, 0.67), 0 4.8px 14.4px rgba(0, 0, 0, 0.53);

@mixin shadow($elevation: $elevation-low) {
  // This mixin: https://gist.github.com/serglo/f9f0be9a66fd6755a0bda85f9c64e85f
  // Another idea: https://codepen.io/sdthornton/pen/wBZdXq
  // Material Design default alphas: 0.14, 0.12, and 0.2

  @if ($elevation == 0) {
    box-shadow: none;
  } @else if ($elevation == 1) {
    box-shadow: 0 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.64),
      0 0.2rem 0.1rem -0.1rem rgba(0, 0, 0, 0.62),
      0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.7);
  } @else if ($elevation == 2) {
    box-shadow: 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.64),
      0 0.3rem 0.1rem -0.2rem rgba(0, 0, 0, 0.62),
      0 0.1rem 0.5rem 0 rgba(0, 0, 0, 0.7);
  } @else if ($elevation == 3) {
    box-shadow: 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.64),
      0 0.3rem 0.3rem -0.2rem rgba(0, 0, 0, 0.62),
      0 0.1rem 0.8rem 0 rgba(0, 0, 0, 0.7);
  } @else if ($elevation == 4) {
    box-shadow: 0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.64),
      0 0.1rem 1rem 0 rgba(0, 0, 0, 0.62),
      0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.7);
  } @else if ($elevation == 6) {
    box-shadow: 0 0.6rem 1rem 0 rgba(0, 0, 0, 0.64),
      0 0.1rem 1.8rem 0 rgba(0, 0, 0, 0.62),
      0 0.3rem 0.5rem -0.1rem rgba(0, 0, 0, 0.7);
  } @else if ($elevation == 8) {
    box-shadow: 0 0.8rem 1rem 0.1rem rgba(0, 0, 0, 0.64),
      0 0.3rem 1.4rem 0.2rem rgba(0, 0, 0, 0.62),
      0 0.5rem 0.5rem -0.3rem rgba(0, 0, 0, 0.7);
  } @else if ($elevation == 9) {
    box-shadow: 0 0.9rem 1.2rem 0.1rem rgba(0, 0, 0, 0.64),
      0 0.3rem 1.6rem 0.2rem rgba(0, 0, 0, 0.62),
      0 0.5rem 0.6rem -0.3rem rgba(0, 0, 0, 0.7);
  } @else if ($elevation == 12) {
    box-shadow: 0 1.2rem 1.7rem 0.2rem rgba(0, 0, 0, 0.64),
      0 0.5rem 2.2rem 0.4rem rgba(0, 0, 0, 0.62),
      0 0.7rem 0.8rem -0.4rem rgba(0, 0, 0, 0.7);
  } @else if ($elevation == 16) {
    box-shadow: 0 1.6rem 2.4rem 0.2rem rgba(0, 0, 0, 0.64),
      0 0.6rem 3rem 0.5rem rgba(0, 0, 0, 0.62),
      0 0.8rem 1rem -0.5rem rgba(0, 0, 0, 0.7);
  } @else if ($elevation == 24) {
    box-shadow: 0 2.4rem 3.8rem 0.3rem rgba(0, 0, 0, 0.64),
      0 0.9rem 4.6rem 0.8rem rgba(0, 0, 0, 0.62),
      0 1.1rem 1.5rem -0.7rem rgba(0, 0, 0, 0.7);
  }

  @include animation-easing-standard();
}

@mixin shadow-hover() {
  @include shadow($elevation-hover);
}
