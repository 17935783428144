@import "./../../styles/variables";
@import "./../../styles/fonts";
@import "./../../styles/animations";
@import "./../../styles/shadows";

.button {
  @include font-button();
  @include animation-easing-standard();
  border: none;
  border-radius: $radius-button;
  padding: $spacing-default;
  background: $color-button;
  color: $color-text-medium;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:active,
  &:hover {
    background: $color-button-hover;
    color: $color-text-high;
  }
}

.secondary {
  background: none;
  color: $color-text-low;
}

.warning {
  background: $color-warning;
  color: $color-warning-on;

  &:hover,
  &:active {
    background: $color-warning-hover;
  }
}
