@import "./variables";
@import "./fonts";

.app {
  display: grid;
  grid-template-rows: [body] auto [footer] 5.7rem; // 5.6rem if no border
  width: 100%;
  height: 100%;
}

.app-body {
  position: relative;
  box-sizing: border-box;
  grid-row: body;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.app-body-container {
  box-sizing: border-box;
  margin: $spacing-body;
}

.app-body-container--content {
  margin-top: $spacing-body * 3;
}

.app-body-container--reducedMargin {
  margin: $spacing-body - ($spacing-box / 2);
}

.app-footer {
  z-index: 999;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row: footer;
  background: $color-footer;
}

.app-footer-button {
  display: flex;
  width: 100%;
  height: 100%;
  fill: $color-text-low;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:active,
  &:hover {
    fill: $color-background-on;
  }
}

.app-footer-button--active {
  fill: $color-background-on;
}

.form-header {
  @include font-h6();
  margin: 0 0 $spacing-2000 0;
  color: $color-text-high;
}

.form-label {
  @include font-caption();
  margin-bottom: $spacing-500;
  color: $color-text-low;
}

.form-label-hint {
  @include font-caption();
  margin-bottom: $spacing-default;
  color: $color-text-low;
}

.form-value {
  margin-bottom: $spacing-1500;
  color: $color-text-high;
}

.form-spacing-marginTop {
  margin-top: $spacing-1500;
}

.form-spacing-marginRight {
  margin-right: $spacing-default;
}

.form-disabled {
  opacity: 0.3;
  cursor: auto !important;
}

.negative {
  color: $color-negative;
}

.warning {
  color: $color-warning;
}
