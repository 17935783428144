@import "./../../styles/variables";
@import "./../../styles/fonts";

.container {
  padding: $spacing-1000;
  background: $color-error;
  color: $color-error-on;
}

.errorMessage {
  display: grid;
  grid-template-columns: [main] auto [toggle] min-content;
  align-items: center;

  .label {
    grid-column: main;
    @include font-overline();
  }

  .message {
    grid-column: main;
  }

  .toggle {
    grid-column: toggle;
    cursor: pointer;

    svg {
      display: block;
    }
  }
}

.stack {
  @include font-body2();
  white-space: pre-wrap;
  overflow-wrap: break-word; // Doesn't work in a grid
  word-wrap: break-word; // Doesn't work in a grid

  &:first-child {
    margin-top: $spacing-1000;
  }
}
