$font-family: "Roboto";

// https://material.io/design/typography/the-type-system.html#type-scale
$font-h1-size: 9.6rem;
$font-h1-spacing: (-0.15rem / $font-h1-size) * 1rem;
$font-h1-weight: 300;
$font-h2-size: 6rem;
$font-h2-spacing: (-0.05rem / $font-h2-size) * 1rem;
$font-h2-weight: 300;
$font-h3-size: 4.8rem;
$font-h3-spacing: 0;
$font-h3-weight: 400;
$font-h4-size: 3.4rem;
$font-h4-spacing: (0.025rem / $font-h4-size) * 1rem;
$font-h4-weight: 400;
$font-h5-size: 2.4rem;
$font-h5-spacing: 0rem;
$font-h5-weight: 400;
$font-h6-size: 2rem;
$font-h6-spacing: (0.015rem / $font-h6-size) * 1rem;
$font-h6-weight: 500;
$font-body1-size: 1.6rem;
$font-body1-spacing: (0.05rem / $font-body1-size) * 1rem;
$font-body1-weight: 400;
$font-body2-size: 1.4rem;
$font-body2-spacing: (0.025rem / $font-body2-size) * 1rem;
$font-body2-weight: 400;
$font-button-size: 1.4rem;
$font-button-spacing: (0.125rem / $font-button-size) * 1rem;
$font-button-weight: 500;
$font-caption-size: 1.2rem;
$font-caption-spacing: (0.04rem / $font-caption-size) * 1rem;
$font-caption-weight: 400;
$font-overline-size: 1rem;
$font-overline-spacing: (0.15rem / $font-caption-size) * 1rem;
$font-overline-weight: 400;

@mixin font-h1 {
  font-size: $font-h1-size;
  font-weight: $font-h1-weight;
  letter-spacing: $font-h1-spacing;
}

@mixin font-h2 {
  font-size: $font-h2-size;
  font-weight: $font-h2-weight;
  letter-spacing: $font-h2-spacing;
}

@mixin font-h3 {
  font-size: $font-h3-size;
  font-weight: $font-h3-weight;
  letter-spacing: $font-h3-spacing;
}

@mixin font-h4 {
  font-size: $font-h4-size;
  font-weight: $font-h4-weight;
  letter-spacing: $font-h4-spacing;
}

@mixin font-h5 {
  font-size: $font-h5-size;
  font-weight: $font-h5-weight;
  letter-spacing: $font-h5-spacing;
}

@mixin font-h6 {
  font-size: $font-h6-size;
  font-weight: $font-h6-weight;
  letter-spacing: $font-h6-spacing;
}

@mixin font-body1 {
  font-size: $font-body1-size;
  font-weight: $font-body1-weight;
  letter-spacing: $font-body1-spacing;
}

@mixin font-body2 {
  font-size: $font-body2-size;
  font-weight: $font-body2-weight;
  letter-spacing: $font-body2-spacing;
}

@mixin font-button {
  font-size: $font-button-size;
  font-weight: $font-button-weight;
  letter-spacing: $font-button-spacing;
}

@mixin font-caption {
  font-size: $font-caption-size;
  font-weight: $font-caption-weight;
  letter-spacing: $font-caption-spacing;
}

@mixin font-overline {
  font-size: $font-overline-size;
  font-weight: $font-overline-weight;
  letter-spacing: $font-overline-spacing;
  text-transform: uppercase;
}
